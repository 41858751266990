import { cloneDeep, isArray } from 'lodash';
import moment from 'moment';

export function removeEmptyObject(obj: object): object {
    const result = {};
    Object.entries(obj).forEach(([k, v]) => {
        if (v !== null && v !== '') {
            result[k] = v;
        }
    });

    return result;
}

export function changeToParam(obj: Object) {
    obj = cloneDeep(obj);
    obj = removeEmptyObject(obj);
    const result = {};

    Object.entries(obj).forEach(([k, v]) => {
        if (isArray(v)) {
            let temp = '';
            v.forEach((e, i) => {
                temp += e;
                if (v.length - 1 !== i) {
                    temp += `,`;
                }
            });
            result[k] = temp;
        } else if (v instanceof Date) {
            result[k] = toDateString(v);
        } else {
            result[k] = v;
        }
    });
    return result;
}

export function arrayToString(input: string[]) {
    let temp = '';
    input.forEach((e, i) => {
        temp += e;
        if (input.length - 1 !== i) {
            temp += `,`;
        }
    });
    return temp;
}

export function downloadFile(data: any, fileName = 'excel.xlsx') {
    let blob = new Blob([data]);
    let url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
}

export function previewPDF(data: any, fileName = 'report.pdf') {
    let blob = new Blob([data], { type: 'application/pdf' });
    let url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
}

export function constructUrlWithQueryParams(baseUrl: string, queryParams: any): string {
    const queryString = Object.keys(queryParams).map((key) => key + '=' + queryParams[key]).join('&');
    return `${baseUrl}?${queryString}`;
}

export function toDateString(date: Date) {
    return moment(date).format('YYYY-MM-DDTHH:mm:ss');
}
